import React, { createContext, useState, useEffect } from "react";
import Client from "shopify-buy";

const client = Client.buildClient({
  domain: "globaladviseralpha.myshopify.com",
  storefrontAccessToken: "d13af82624f977185a94a0a71fbbab26",
});

const defaultValues = {
  isCartOpen: "",
  cart: [],
  client,
};

export const StoreContext = createContext(defaultValues);

export const StoreProvider = ({ children }) => {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [checkout, setCheckout] = useState(null);
  const [isAustralia, setIsAustralia] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // adding product to cart
  const addProductToCart = async (variantId, qty) => {
    let id;

    try {
      id = await client.checkout.create();
    } catch (e) {
      console.log(e);
    }
    try {
      const lineItems = [
        {
          variantId: variantId,
          quantity: qty,
        },
      ];

      const singleCheckout = await client.checkout.addLineItems(
        id.id,
        lineItems
      );

      if (singleCheckout) {
        window.location.assign(singleCheckout.webUrl);
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // removing product from cart
  // needs the line item id unlike adding
  const removeProductFromCart = async (lineItemID) => {
    try {
      const newCheckout = await client.checkout.removeLineItems(checkout.id, [
        lineItemID,
      ]);

      setCheckout(newCheckout);
    } catch (e) {
      console.log(e);
    }
  };

  const updateProductFromCart = async (lineItemID, newQty) => {
    try {
      const newCheckout = await client.checkout.updateLineItems(checkout.id, [
        { id: lineItemID, quantity: newQty },
      ]);

      setCheckout(newCheckout);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((res) => res.json())
      .then((data) => {
        setIsAustralia(data.country_name === "Australia");
      })
      .catch(() => setIsAustralia(true));
  }, []);

  const goToCheckout = (variantId, qty = 1) => {
    setIsLoading(true);
    addProductToCart(variantId, qty);
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addProductToCart,
        removeProductFromCart,
        updateProductFromCart,
        isCartOpen,
        setIsCartOpen,
        checkout,
        isLoading,
        goToCheckout,
        isAustralia,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
